<template>
  <div id="app">
    <img src="./assets/affalogo.jpg" alt="Şirketinizi temsil eden bir fotoğraf">
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>
/* Bu kısım, görüntüyü sayfanın tamamını kaplamak için gereken stilleri içerir */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.718);
}

#app {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app img {
  max-width: 100%;
  max-height: 100%;
}
</style>
