<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/affalogo.jpg">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
<style>
img{
  width: 1400px;
}
template{
  background-color: black;
}
</style>
